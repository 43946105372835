import ReactDOM from 'react-dom'
import { useState } from 'react';
import './styles.css'
import App from './App'
import { Content, ContentState } from './components/ContentState'
import { useSnapshot } from "valtio"

function Overlay() {
  const snap = useSnapshot(ContentState)

  /*console.log(snap.person)
  snap.person ? console.log(Content[0][snap.person]["name"]) : console.log('empty')
  <Info 
  img={Content[0][snap.person]["image"]} 
  name={Content[0][snap.person]["name"]} 
  job={Content[0][snap.person]["job"]} 
  description={Content[0][snap.person]["description"]} />
*/

const [isActive, setActive] = useState(false);

const toggleClass = () => {
  setActive(!isActive);
};

  return (
    <>
    <div id="introbox" className='introbox'>
      <img src="/tazze.jpg" />
      <h2>IL CAFFE’ DEVE ESSERE GUSTATO CALDO, SEDUTI E IN BUONA COMPAGNIA</h2>
      <h3 onClick={() => {
        document.getElementById("introbox").remove()
      }} style={{ color: 'orange' }}>[ <span style={{ color: '#333' }}>ENTRA NEL SITO</span> ]</h3>
    </div>
     <div className='framewrapper'>
        <ul className='nav'>
          <li>
            <button onClick={() => { ContentState.page = 'who' }}>WHO I<span>S</span> <span>TRIBÙ</span></button>
          </li>
          <li>
            <button onClick={() => { ContentState.page = 'why' }}>WH<span>Y</span> <span>TRIBÙ</span></button>
          </li>
          <li>
            <button onClick={() => { ContentState.page = 'expertise' }}>E<span>X</span>PERTISE <span>TRIBÙ</span></button>
          </li>
          <li>
            <button onClick={() => { ContentState.page = 'credits' }}><span>C</span>REDITS <span>TRIBÙ</span></button>
          </li>
          <li>
            <button onClick={() => { ContentState.page = 'contacts' }}>CONTACT<span>S</span> <span>TRIBÙ</span></button>
          </li>
        </ul>
      </div>
      <div className='mobile framewrapper'>
        <div className={isActive ? "open menutrigger" : "menutrigger"} onClick={toggleClass}>
        </div>
        {isActive 
        ? 
        <ul className={isActive ? "open nav" : "nav"}>
        <li>
          <button onClick={() => { ContentState.page = 'who' }}>WHO I<span>S</span> <span>TRIBÙ</span></button>
        </li>
        <li>
          <button onClick={() => { ContentState.page = 'why' }}>WH<span>Y</span> <span>TRIBÙ</span></button>
        </li>
        <li>
          <button onClick={() => { ContentState.page = 'expertise' }}>E<span>X</span>PERTISE <span>TRIBÙ</span></button>
        </li>
        <li>
          <button onClick={() => { ContentState.page = 'credits' }}><span>C</span>REDITS <span>TRIBÙ</span></button>
        </li>
        <li>
          <button onClick={() => { ContentState.page = 'contacts' }}>CONTACT<span>S</span> <span>TRIBÙ</span></button>
        </li>
      </ul>
        : 
        <div></div>
        }
        
      </div>

      {snap.person
        ?
        <div className="infowrapper">
          <button className="closeme" type='button' onClick={() => { ContentState.person = '' }}></button>
          <div className="image">
            <img src={Content[0][snap.person]["image"]} alt={Content[0][snap.person]["name"]} />
          </div>
          <div className="info">
            <h2 className="name" dangerouslySetInnerHTML={{ __html: Content[0][snap.person]["name"] }} />
            <h3 className="job">{Content[0][snap.person]["job"]}</h3>
            <p className="description" dangerouslySetInnerHTML={{ __html: Content[0][snap.person]["description"] }} />
          </div>
        </div>
        :
        <div></div>
      }
      {snap.page
        ?
        <div className="infowrapper">
          <button className="closeme" type='button' onClick={() => { ContentState.page = '' }}></button>
          <div className="info">
            <img src="./cialda.jpg" alt="Triibù" />
            <h2 className="name" dangerouslySetInnerHTML={{ __html: Content[0][snap.page]["title"] }} />
            <p className="description" dangerouslySetInnerHTML={{ __html: Content[0][snap.page]["content"] }} />
          </div>
        </div>
        :
        <div></div>
      }

      <div className='overlay' style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
        <img className='logomain' src="/logo.png" alt="Triibù" style={{ position: 'absolute', bottom: 40, left: 40, width: '80px' }} />
        <a className='sloganbottom'  href="#" style={{ position: 'absolute', bottom: 40, left: 130, fontSize: '13px' }}>
          Triibù_firstly
          <br />
          people
        </a>
        <div className='slogantop' style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>IL CAFFÈ DEVE ESSERE GUSTATO CALDO, SEDUTI E IN BUONA COMPAGNIA —</div>

        <div className='welcome' style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px', color: 'orange' }}>[ <span style={{ color: '#333' }}>WELCOME TO TRIIBÙ</span> ]</div>
      </div>
    </>
  )
}

ReactDOM.render(
  <>
    <App />
    <Overlay />
  </>,
  document.getElementById('root')
)