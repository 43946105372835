const THREE = require("three");
global.THREE = THREE;
import { Suspense, useRef, forwardRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Preload, Image as ImageImpl, ScrollControls, Scroll, useScroll, Html } from '@react-three/drei'
import { ContentState } from './components/ContentState'
import { useSnapshot } from "valtio"
import { Flex, Box } from "@react-three/flex";


function Image(props) {
  const ref = useRef()
  const group = useRef()
  const data = useScroll()
  useFrame((state, delta) => {
   // group.current.position.z = THREE.MathUtils.damp(group.current.position.z, Math.max(0, data.delta * 50), 4, delta)
    //ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, Math.max(0, 1 - data.delta * 1000), 4, delta)
  })
  return (
    <group ref={group}>
      <ImageImpl ref={ref} {...props} />
    </group>
  )
}

const Tag = forwardRef(({ name, job, ...props }, ref) => {
  return (
    <Html ref={ref} center {...props}>
      <h3>{name}</h3>
      <h4>{job}</h4>
    </Html>
  )
})


function Page({ m = 0.0005, urls, names, jobs, myname, ...props }) {

  const snap = useSnapshot(ContentState)
  
  const { width } = useThree((state) => state.viewport)
  let w = width < 10 ? 1.5 / 3 : 1 / 3
  if(window.innerWidth <= 768) {
    console.log('mobile') 
    w = 3 / 3
  } 
  
  const mrefm = useRef()

  const [mref1, setHover1] = useState(false);
  const [mref2, setHover2] = useState(false);
  const [mref3, setHover3] = useState(false);

  if(window.innerWidth <= 768) { 

    return (
      <group  {...props}>
        <Flex size={[300, 300, 300]} flexDirection="row" flexWrap="nowrap">
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => {
            console.log(snap.person)
            ContentState.person = myname[0]
          }}
          onPointerOver={(event) => setHover1(true)}
          onPointerOut={(event) => setHover1(false)}
          >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[0]} />
          {mref1 && <Tag className="content small" name={names[0]} job={jobs[0]} />}
        </mesh>
        </Box>
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[1] }}
          onPointerOver={(event) => setHover2(true)}
          onPointerOut={(event) => setHover2(false)} >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[1]} />
          {mref2 && <Tag className="content medium" name={names[1]} job={jobs[1]} />}
        </mesh>
        </Box>
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[2] }}
          onPointerOver={(event) => setHover3(true)}
          onPointerOut={(event) => setHover3(false)}
           >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[2]} />
          {mref3 && <Tag className="content big" name={names[2]} job={jobs[2]} />}
        </mesh>
        </Box>
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[3] }}
          onPointerOver={(event) => setHover3(true)}
          onPointerOut={(event) => setHover3(false)}
           >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[3]} />
          {mref3 && <Tag className="content big" name={names[3]} job={jobs[3]} />}
        </mesh>
        </Box>
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[4] }}
          onPointerOver={(event) => setHover3(true)}
          onPointerOut={(event) => setHover3(false)}
           >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[4]} />
          {mref3 && <Tag className="content big" name={names[4]} job={jobs[4]} />}
        </mesh>
        </Box>
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[5] }}
          onPointerOver={(event) => setHover3(true)}
          onPointerOut={(event) => setHover3(false)}
           >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[5]} />
          {mref3 && <Tag className="content big" name={names[5]} job={jobs[5]} />}
        </mesh>
        </Box>
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[6] }}
          onPointerOver={(event) => setHover3(true)}
          onPointerOut={(event) => setHover3(false)}
           >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[6]} />
          {mref3 && <Tag className="content big" name={names[6]} job={jobs[6]} />}
        </mesh>
        </Box>
        <Box>
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[0] }}
          onPointerOver={(event) => setHover3(true)}
          onPointerOut={(event) => setHover3(false)}
           >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[0]} />
          {mref3 && <Tag className="content big" name={names[0]} job={jobs[0]} />}
        </mesh>
        </Box>
        </Flex>
      </group>
    )

  } else {
    return (
      <group  {...props}>
  
        <mesh ref={mrefm}
          onClick={(event) => {
            console.log(snap.person)
            ContentState.person = myname[0]
          }}
          onPointerOver={(event) => setHover1(true)}
          onPointerOut={(event) => setHover1(false)}
          position={[-width * w, 0, -1]} >
          <Image scale={[width * w - m * 2, 5, 1]} url={urls[0]} />
          {mref1 && <Tag className="content small" name={names[0]} job={jobs[0]} />}
        </mesh>
  
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[1] }}
          onPointerOver={(event) => setHover2(true)}
          onPointerOut={(event) => setHover2(false)} position={[0, 0, 0]} >
          <Image scale={[width * w - m * 2, 5, 2]} url={urls[1]} />
          {mref2 && <Tag className="content medium" name={names[1]} job={jobs[1]} />}
        </mesh>
  
        <mesh ref={mrefm}
          onClick={(event) => { ContentState.person = myname[2] }}
          onPointerOver={(event) => setHover3(true)}
          onPointerOut={(event) => setHover3(false)}
          position={[width * w, 0, 1]} >
          <Image scale={[width * w - m * 2, 5, 3]} url={urls[2]} />
          {mref3 && <Tag className="content big" name={names[2]} job={jobs[2]} />}
        </mesh>
      </group>
    )

  }
 
}



function Pages() {
  const { width } = useThree((state) => state.viewport)

  if(window.innerWidth <= 768) { 
  return (
    <>
    <Flex flexDirection="row" flexWrap="nowrap">
      <Box>
      <Page 
        urls={['/profilepics/bevila.jpg', '/profilepics/maurizio.jpg', '/profilepics/pier.jpg', '/profilepics/dario.jpg', '/profilepics/cristina.jpg', '/profilepics/fabio.jpg', '/profilepics/borri.jpg']}
        myname={["bevilacqua", "vasapolli", "fossa", "dursi", "alzati", "ferrari", "borri"]}
        names={[<>DARIO BEVILA<span>Q</span>UA</>, <>MAURI<span>Z</span>IO VASAPOLLI</>, <>PIERLUIGI FO<span>S</span>SA</>, <>DARIO <span>D</span>URSI</>, <>CRISTI<span>N</span>A ALZATI</>, <>FABIO FE<span>R</span>RARI</>, <>ORES<span>T</span>E BORRI</>]}
        jobs={['ART DIRECTION_CREATIVITY & Co', 'COORDINATOR_BUDGETING_MKTG', 'IMAGING DIRECTOR', 'WEBSITE & SEO', 'GRAPHIC & WEB DESIGNER', 'COPYWRITING OFF-ONLINE COMM', 'COMUNICATION_STRATEGY']} />
      </Box>
      
      </Flex>
    </>
  )
  /* <Box>
      <Page 
        urls={["/profilepics/borri.jpg","/profilepics/fabio.jpg","/profilepics/cristina.jpg","/profilepics/dario.jpg","/profilepics/pier.jpg","/profilepics/maurizio.jpg","/profilepics/bevila.jpg"]}
        myname={["borri","ferrari","alzati","dursi","fossa","vasapolli","bevilacqua"]}
        names={[<>ORES<span>T</span>E BORRI</>,<>FABIO FE<span>R</span>RARI</>,<>CRISTI<span>N</span>A ALZATI</>,<>DARIO <span>D</span>URSI</>,<>PIERLUIGI FO<span>S</span>SA</>,<>MAURI<span>Z</span>IO VASAPOLLI</>,<>DARIO BEVILA<span>Q</span>UA</>]}
        jobs={["COMUNICATION_STRATEGY","COPYWRITING OFF-ONLINE COMM","GRAPHIC & WEB DESIGNER","WEBSITE & SEO","IMAGING DIRECTOR","COORDINATOR_BUDGETING_MKTG","ART DIRECTION_CREATIVITY & Co"]} />
      </Box>
      <Box>
      <Page 
        urls={['/profilepics/bevila.jpg', '/profilepics/maurizio.jpg', '/profilepics/pier.jpg', '/profilepics/dario.jpg', '/profilepics/cristina.jpg', '/profilepics/fabio.jpg', '/profilepics/borri.jpg']}
        myname={["bevilacqua", "vasapolli", "fossa", "dursi", "alzati", "ferrari", "borri"]}
        names={[<>DARIO BEVILA<span>Q</span>UA</>, <>MAURI<span>Z</span>IO VASAPOLLI</>, <>PIERLUIGI FO<span>S</span>SA</>, <>DARIO <span>D</span>URSI</>, <>CRISTI<span>N</span>A ALZATI</>, <>FABIO FE<span>R</span>RARI</>, <>ORES<span>T</span>E BORRI</>]}
        jobs={['ART DIRECTION_CREATIVITY & Co', 'COORDINATOR_BUDGETING_MKTG', 'IMAGING DIRECTOR', 'WEBSITE & SEO', 'GRAPHIC & WEB DESIGNER', 'COPYWRITING OFF-ONLINE COMM', 'COMUNICATION_STRATEGY']} />
      </Box>*/
  } else {
    return (
      <>
        <Page position={[-width * 1, 0, 0]}
          urls={['/profilepics/dario.jpg', '/profilepics/cristina.jpg', '/profilepics/fabio.jpg']}
          myname={["dursi", "alzati", "ferrari"]}
          names={[<>DARIO <span>D</span>URSI</>, <>CRISTI<span>N</span>A ALZATI</>, <>FABIO FE<span>R</span>RARI</>]}
          jobs={['WEBSITE & SEO', 'GRAPHIC & WEB DESIGNER', 'COPYWRITING OFF-ONLINE COMM']}
        />
  
        <Page position={[width * 0, 0, 0]}
          urls={['/profilepics/bevila.jpg', '/profilepics/maurizio.jpg', '/profilepics/pier.jpg']}
          myname={["bevilacqua", "vasapolli", "fossa"]}
          names={[<>DARIO BEVILA<span>Q</span>UA</>, <>MAURI<span>Z</span>IO VASAPOLLI</>, <>PIERLUIGI FO<span>S</span>SA</>]}
          jobs={['ART DIRECTION_CREATIVITY & Co', 'COORDINATOR_BUDGETING_MKTG', 'IMAGING DIRECTOR']} />
  
        <Page position={[width * 1, 0, 0]}
          urls={['/profilepics/dario.jpg', '/profilepics/cristina.jpg', '/profilepics/fabio.jpg']}
          myname={["dursi", "alzati", "ferrari"]}
          names={[<>DARIO <span>D</span>URSI</>, <>CRISTI<span>N</span>A ALZATI</>, <>FABIO FE<span>R</span>RARI</>]}
          jobs={['WEBSITE & SEO', 'GRAPHIC & WEB DESIGNER', 'COPYWRITING OFF-ONLINE COMM']} />
  
        <Page position={[width * 2, 0, 0]}
          urls={['/profilepics/bevila.jpg', '/profilepics/borri.jpg', '/profilepics/pier.jpg']}
          myname={["bevilacqua", "borri", "fossa"]}
          names={[<>DARIO BEVILA<span>Q</span>UA</>, <>ORES<span>T</span>E BORRI</>, <>PIERLUIGI FO<span>S</span>SA</>]}
          jobs={['ART DIRECTION_CREATIVITY & Co', 'COMUNICATION_STRATEGY', 'IMAGING DIRECTOR']} />
  
        <Page position={[width * 3, 0, 0]}
          urls={['/profilepics/bevila.jpg', '/profilepics/maurizio.jpg', '/profilepics/pier.jpg']}
          myname={["bevilacqua", "vasapolli", "fossa"]}
          names={[<>DARIO BEVILA<span>Q</span>UA</>, <>MAURI<span>Z</span>IO VASAPOLLI</>, <>PIERLUIGI FO<span>S</span>SA</>]}
          jobs={['ART DIRECTION_CREATIVITY & Co', 'COORDINATOR_BUDGETING_MKTG', 'IMAGING DIRECTOR']} />
  
        <Page position={[width * 4, 0, 0]}
          urls={['/profilepics/dario.jpg', '/profilepics/cristina.jpg', '/profilepics/fabio.jpg']}
          myname={["dursi", "alzati", "ferrari"]}
          names={[<>DARIO <span>D</span>URSI</>, <>CRISTI<span>N</span>A ALZATI</>, <>FABIO FE<span>R</span>RARI</>]}
          jobs={['WEBSITE & SEO', 'GRAPHIC & WEB DESIGNER', 'COPYWRITING OFF-ONLINE COMM']} />
  
        <Page position={[width * 5, 0, 0]}
          urls={['/profilepics/bevila.jpg', '/profilepics/borri.jpg', '/profilepics/fabio.jpg']}
          myname={["bevilacqua", "borri", "ferari"]}
          names={[<>DARIO BEVILA<span>Q</span>UA</>, <>ORES<span>T</span>E BORRI</>, <>FABIO FE<span>R</span>RARI</>]}
          jobs={['ART DIRECTION_CREATIVITY & Co', 'COMUNICATION_STRATEGY', 'COPYWRITING OFF-ONLINE COMM']} />
  
        <Page position={[width * 6, 0, 0]}
          urls={['/profilepics/bevila.jpg', '/profilepics/maurizio.jpg', '/profilepics/pier.jpg']}
          myname={["bevilacqua", "vasapolli", "fossa"]}
          names={[<>DARIO BEVILA<span>Q</span>UA</>, <>MAURI<span>Z</span>IO VASAPOLLI</>, <>PIERLUIGI FO<span>S</span>SA</>]}
          jobs={['ART DIRECTION_CREATIVITY & Co', 'COORDINATOR_BUDGETING_MKTG', 'IMAGING DIRECTOR']} />
  
        <Page position={[width * 7, 0, 0]}
          urls={['/profilepics/dario.jpg', '/profilepics/cristina.jpg', '/profilepics/fabio.jpg']}
          myname={["dursi", "alzati", "ferrari"]}
          names={[<>DARIO <span>D</span>URSI</>, <>CRISTI<span>N</span>A ALZATI</>, <>FABIO FE<span>R</span>RARI</>]}
          jobs={['WEBSITE & SEO', 'GRAPHIC & WEB DESIGNER', 'COPYWRITING OFF-ONLINE COMM']}
        />
      </>
    )
  }
}

export default function App() {
  var pages = 7 
  var damping = 4
  var distance = 1
  if(window.innerWidth <= 768) {
    pages=8;
    damping = 4
    distance = 2
  }
  return (
    <Canvas gl={{ antialias: false }} dpr={[1, 1.5]}>
      <Suspense fallback={null}>
        <ScrollControls infinite horizontal damping={damping} pages={pages} distance={distance}>
          <Scroll>
            <Pages />
          </Scroll>
          <Scroll html>
          </Scroll>
        </ScrollControls>
        <Preload />
      </Suspense>
    </Canvas>

  )
}
