
import { proxy } from "valtio"

export const Content = [{
  "borri": {
    name: "ORES<span>T</span>E BORRI",
    job: "COMMUNICATION STRATEGY",
    description: `<span>Ha collezionato un numero di riconoscimenti secondo soltanto ai papillon sfoggiati.</span><br/>
        Specialista della grande distribuzione, ideatore di format commerciali di successo, gestisce clienti locali, nazionali e internazionali con la medesima passione.<br/>
        <span>Per lui comunicare non è mai stata un’impresa</span>`,
    image: "/profilepics/borri.jpg"
  },
  "piu": {
    name: "ANDREA PI<span>U</span>",
    job: "SHOPPING CENTER & RETAIL CONSULTANT",
    description: `<span>Nasce come professionista nel settore della Grande Distribuzione Organizzata</span><br/>
         Con un’esperienza consolidata all’interno della più grande catena milanese di supermercati<br/>
         <span>Imprenditore nell’ambito della comunicazione e del marketing operativo.</span>`,
    image: "/profilepics/andrea.jpg"
  },
  "vasapolli": {
    name: "MAURI<span>Z</span>IO VASAPOLLI",
    job: "COORDINATOR BUDGETING MARKETING",
    description: `<span>In Triibù per scelta ma anche per destino, dopo una laurea in lettere con tesi sugli Indiani d’America.</span><br/>
          Nativo varesino, in oltre 30 anni di attività nel mondo della comunicazione pubblicitaria, ha messo la saggezza al servizio di numerose agenzie, gestendo importanti budget sia dal punto di vista strategico sia del coordinamento.`,
    image: "/profilepics/maurizio.jpg"
  },
  "luzardi": {
    name: "ANDREA LUZA<span>R</span>DI",
    job: "ACCOUNT MANAGER",
    description: `<span>Content Producer, Project Manager e Business Developer</span><br/>
             Coordina la produzione di contenuti video e fotografici, branded contents, contenuti editoriali e commerciali.`,
    image: "/profilepics/luzardi.jpg"
  },
  "bevilacqua": {
    name: "DARIO BEVILAC<span>Q</span>UA",
    job: "ART DIRECTION CREATIVITY & Co",
    description: `<span>Art director e Creativo in importanti agenzie di comunicazione</span><br/>
              Know-how su clienti nazionali ed internazionali<br/>
              <span>“Everyone sees what he knows”.</span>`,
    image: "/profilepics/bevila.jpg"
  },
  "ferrari": {
    name: "FABIO FE<span>R</span>RARI",
    job: "COPYWRITING OFF-ON LINE COMM",
    description: `<span>Copywriter con un’anima offline ed online.</span><br/>
              La prima presta servizio in agenzie pubblicitarie<br/>
              <span>La seconda si manifesta nelle mutevoli pieghe del web marketing: content management, online advertising, e-mail marketing, social media.</span>`,
    image: "/profilepics/fabio.jpg"
  },
  "fossa": {
    name: "PIERLUIGI FO<span>S</span>SA",
    job: "IMAGING DIRECTOR",
    description: `<span>Mai come in questo caso, vale più un’immagine di tante parole.</span><br/>
              Nel suo spazio creativo, l’immagine fotografica diventa strumento di comunicazione, attraverso la cura maniacale del dettaglio ed una costante ricerca di nuovi linguaggi visivi, capaci sempre di lasciare il segno.<br/>
              <span>Tutto in un semplice click.</span>`,
    image: "/profilepics/pier.jpg"
  },
  "dursi": {
    name: "DARIO <span>D</span>URSI",
    job: "WEBSITE & SEO",
    description: `<span>Esperto di soluzioni su misura per ogni esigenza online.</span><br/>
        Dalla realizzazione di siti internet al posizionamento sui motori di ricerca, fino alla creazione di campagne di advertising online e social media marketing.<br/>
        <span>Google Partner certificato.</span>`,
    image: "/profilepics/dario.jpg"
  },
  "alzati": {
    name: "CRISTI<span>N</span>A ALZATI",
    job: "GRAPHIC & WEB DESIGNER",
    description: `<span>Cresciuta giocando con i Lego.</span><br/>
         Costruisce seguendo le istruzioni, per poi distruggere tutto e ricostruire seguendo solo l’immaginazione e la fantasia. Ama vedere le idee prendere vita.<br/>
         <span>Il sogno di ogni bambino è inventare e creare ciò che passa per la mente, allora perché smettere di sognare.</span>`,
    image: "/profilepics/cristina.jpg"
  },
  "who": {
    title: "<b>who i</b><span>s</span> triibù",
    content: `Triibù è un gruppo di nativi pubblicitari<br/>
    con tante battaglie sulle spalle quante idee nella testa.<br/>
    <span>Triibù è l’unione di differenti esperienze professionali<br/>
    che si completano come i tasselli di un mosaico sempre nuovo.</span><br/>
    In Triibù i ruoli contano, ma non sono sempre gli stessi.<br/>
    <span>Tutti possono essere leader o gregari, secondo progetto e priorità.</span><br/>
    Triibù è la certezza<br/>
    di disporre della persona giusta al posto giusto, quando serve.`,
  },
  "why": {
    title: "<b>wh</b><span>y</span> triibù",
    content: `Triibù è una comunità nata per comunicare.<br/>
    Con tutti i mezzi, dal tam-tam in poi.<br/>
    <span>Triibù è una realtà condivisa,<br/>
    in parte virtuale,<br/>
    che può assumere svariate forme.</span><br/>
    Tutte estremamente concrete.<br/>
    <span>Triibù può essere 1 ma anche 10 ed anche di più.<br/>
    Può modificarsi, ampliarsi, dividersi, a seconda della sfida.</span><br/>
    Triibù si muove in modo leggero, veloce, improvviso.<br/>
    Ma all’occorrenza sa essere organizzata,<br/>
    strutturata, articolata.<br/>
    <span>Triibù se serve si materializza, puntuale,<br/>
    essenziale, affidabile.</span>
    Altrimenti sparisce, invisibile<br/>
    ma vigile, pronta a rientrare in gioco.`,
  },
  "expertise": {
    title: "<b>e</b><span>x</span><b>pertise</b> triibù",
    content: `Triibù sa tutto<br/>
    delle dinamiche di comunicazione<br/>
    relative ai grandi centri commerciali.<br/>
    <span>Lo sa perchè negli anni<br/>
    ha realizzato esperienze con grandi players.</span><br/>
    Triibù sa e mette in pratica,<br/>
    portando risultati concreti.<br/>
    <span>Sempre estremamente creativi.</span>`,
  },
  "credits": {
    title: "<span>c</span><b>redits</b> tribù",
    content: `<b>[</b> HANNO PRESO UN CAFFE’ IN NOSTRA COMPAGNIA <b>]</b>
         <div class="logos">
         <img class="logo-img " src="./18.png" />
         <img class="logo-img " src="./19.png" />
         <img class="logo-img " src="./20.png" />
         <img class="logo-img " src="./21.png" />
         <img class="logo-img " src="./22.png" />
         <img class="logo-img " src="./23.png" />
         <img class="logo-img " src="./24.png" />
         <img class="logo-img " src="./25.png" />
         <img class="logo-img " src="./26.png" />
         <img class="logo-img " src="./27.png" />
         <img class="logo-img " src="./28.png" />
         <img class="logo-img " src="./29.png" />
         <img class="logo-img " src="./30.png" />
         <img class="logo-img " src="./31.png" />
         <img class="logo-img " src="./32.png" />
         <img class="logo-img " src="./33.png" />
         <img class="logo-img " src="./34.png" />
         <img class="logo-img " src="./35.png" />
         <img class="logo-img " src="./36.png" />
         <img class="logo-img " src="./37.png" />
         <img class="logo-img " src="./38.png" />
         <img class="logo-img " src="./39.png" />
         <img class="logo-img " src="./40.png" />
         <img class="logo-img " src="./41.png" />
         </div>`,
  },
  "contacts": {
    title: "conta<span>c</span>ts",
    content: `<b>[</b> VARESE_MILANO_ITALY_TRIIBU.IT_INFO@TRIIBU.IT <b>]</b>`,
  },
}]

export const ContentState = proxy({
  person: "",
  page: ""
})
